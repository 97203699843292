@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

.app {
  // font-family: 'Roboto Slab', serif;
}


.App-header {
  background-color: White;
  min-height: 100vh;
  display: flex;
  color: black;
  flex-direction: column;
  padding-top: 30px;
  @media screen and (max-width: 900px) {
    padding-top: 130px;
  }
}


.staff{
  padding-top: 130px;
}
